h2.sandbox-mode-text {
  font-weight: 700;
}

h3.product-manifest {
  font-weight: 700;
}

p.sandboxing-paragraph {
  margin-bottom: 0px;
}

.warning {
  font-weight: 700;
}

.notice {
  font-family: Segoe UI;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
  background: #fcf3f3;
  border: 1px solid #f2b3bf;
  border-radius: 0px;
  height: 35px;
  padding: 5px 0px;
  margin-top: 0;
  color: #c31d1d;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sandbox-section {
  box-sizing: border-box;
  padding: 20px 25px 10px;
  overflow: auto;
  margin-bottom: 30px;
}

.section {
  box-sizing: border-box;
  padding: 20px 25px 30px;
}

.switch {
  margin: 0px 0;
  padding: 20px;
  padding-left: 0px;
  border-bottom: solid 1px #dddddd;
}

textarea {
  outline: solid 1px #999;
  font-family: monospace;
  font-size: 0.95rem;
  display: block;
  width: 100%;
  height: 500px;
  margin-bottom: 1rem;
  background: #f6f6f6;
}

.button {
  font-size: 1em;
  line-height: 1.5em;
  padding: 0.33em 1em !important;
  font-weight: normal;
  text-transform: uppercase;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border-radius: 0;
  border: none;
  width: auto;
  height: auto;
  cursor: pointer;
  float: right;
}

.button-primary {
  color: #fff;
}

.sandbox-text {
  position: relative;
  top: 2px;
  left: 2px;
  font-family: Segoe UI;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}

.first-button {
  margin-right: 10px;
}

.sandbox-switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
}

.sandbox-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  bottom: 0.1px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #00729e;
}

/* input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
} */

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
