.wrapper {
  position: fixed;
  left: 65px;
  right: 0;
  bottom: 0;
  top: 0;
}

.wrapper .header {
  background: #fff;
  height: 60px;
  margin-left: 10px;
}

.wrapper .breadcrumb {
  background: #fff;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 12px;
}

.wrapper .breadcrumb > section {
  margin-left: 10px;
  height: 100%;
  display: flex;
  align-items: center;
}

.wrapper .divider {
  border-top: 1px solid #dddddd;
}

.wrapper .divider-footer {
  position: absolute;
  bottom: 50px;
  right: 0;
  left: 0;
  border-top: 1px solid #dddddd;
}

.wrapper .container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: #F6F6F6;
  bottom: 50px;
  top: 112px;
  right: 0px;
  left: 65px;
  position: fixed;
  flex-grow: 1;
}

.wrapper .footer {
  position: fixed;
  left: 65px;
  right: 0;
  bottom: 0;
  background-color: white;
}